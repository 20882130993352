import React, { useEffect } from 'react';
import { useState } from 'react';
import YouTube from 'react-youtube';
import { Container, Button, Grid, Paper, Typography, List, ListItem, ListItemText, Divider, Collapse, ListItemButton, ListItemIcon, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Header from "../components/Header"
import Footer from '../components/footer';
import overallservices from "../services/overallservices";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Video from '../components/video';
import { Assessment } from '@mui/icons-material';
import Dynamicvideo from "../components/Dynamicvideo";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const App = (props) => {
  const { number, name, description, details, uid } = props;
  const [openTopicIndex, setOpenTopicIndex] = useState(null);
  const [openSubtopicIndices, setOpenSubtopicIndices] = useState([]);
  const [selectedSubtopicIndex, setSelectedSubtopicIndex] = useState(null);
  const [open, setOpen] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const [video, setVideo] = useState("");
  const [dynamicvideo, setdynamicVideo] = useState("");
  const { id } = useParams();
  const Navigate = useNavigate();
  const userId = user.userId;
  const courseId = id;
  const token = user.token;
  const [completedSubtopicIndices, setCompletedSubtopicIndices] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [contentid, setcontentid] = useState([]);
  const [showAssessment, setShowAssessment] = useState(false);
  const [courseContent, setCoursecontent] = useState(null);
  const [showAssessmentScreen, setShowAssessmentScreen] = useState(false);
  const [contentType, setContentType] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  const NavigateToEnroll = () => {
    const courseDetailPath = `/coursedetails/${id}/${uid}`;
   
    Navigate(courseDetailPath);
  };
 

  const handleTopicClick = (index) => {
    setOpenTopicIndex(index);
  };

  useEffect(() => {
    overallservices
      .coursecontent(user?.userId, id)
      .then((response) => {
        if (response.message == "Success") {
          setCoursecontent(response.data);
        }
      })
      .catch((error) => {
        console.log("herere", error);
      });
  }, []);
  const SuccessModal = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <DialogContentText>
            Course enrolled successfully!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={NavigateToEnroll} variant="contained" color="primary">
         Start Course
      </Button>
        </DialogActions>
      </Dialog>
    );
  };
  useState(() => {
    console.log("DATA", assessments, video);
  }, [assessments, video]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const Enrollcourse = () => {
    overallservices
      .enrollstudent(userId, courseId, token)
      .then((data) => {
        console.log('Enrollment successful:', data);
     
        openModal(); 
      })
      .catch((error) => {
        console.error('Enrollment failed:', error.message);
       
      });
  };

  const handleContentView = (content) => {
    setVideo(content.video);
    setAssessments(content.assessments);
    setcontentid(content.id);
    setContentType(content.contentType);
    setdynamicVideo(content.video);
    setCompletedSubtopicIndices((prevIndices) => [...prevIndices, content.id]);
  };

  const handleSubtopicClick = (topicIndex, subtopicIndex) => {
    const updatedSubtopicIndices = [...openSubtopicIndices];
    updatedSubtopicIndices[topicIndex] = subtopicIndex;
    setOpenSubtopicIndices(updatedSubtopicIndices);
  };

  return (
    <>
      <Grid style={{ backgroundColor: "#fbf1ea", paddingBottom: "30px" }}>
        <Header />
        <Container style={{ backgroundColor: "#FFFF", borderRadius: 20, padding: '20px', maxWidth: '80%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} sm={8}>
              <video
                style={{ width: "600px" }}
                controls
              >
              </video>
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "30%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <span>🔒</span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Course Playlist
              </Typography>
              <Paper elevation={3} style={{ padding: '16px', backgroundColor: "#fbf1ea", color: "black" }}>
                <List component="nav">
                  {courseContent?.chapters.map((el, index) => (
                    <div key={index}>
                      <ListItemButton onClick={() => handleTopicClick(index)}>
                        <ListItemText primary={el.name} />
                      </ListItemButton>
                      <Collapse in={openTopicIndex === index} style={{ height: openTopicIndex === index ? "auto" : 0, overflow: "hidden", transition: "height 0.3s" }}>
                        <List disablePadding>
                          {el.contents?.map((content, contentIndex) => (
                            <ListItemButton
                              key={contentIndex}
                              sx={{ paddingLeft: 4, cursor: "pointer" }}
                              onClick={() => handleContentView(content)}
                            >
                              <ListItemIcon></ListItemIcon>
                              <ListItemText primary={content.name} />
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                      <Divider />
                    </div>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Container style={{ backgroundColor: "#FFFF", borderRadius: 20, padding: '20px', maxWidth: '80%', marginBottom: "100px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} sm={8}>
            <b>About this Course</b>
            <p>This comprehensive course on Computer Studies for Class 12 provides a solid foundation in the field of computer science. Explore the fundamentals of computer science, delve into programming concepts, learn about data structures and algorithms, and understand software development principles. Engage in interactive activities such as the 'Code Master Challenge' game and test your knowledge with the 'Computing Concepts Quiz.' Gain valuable insights into computer networks, security, database management systems, and emerging technologies. Equip yourself with the essential knowledge and skills needed to excel in the dynamic world of computing.</p>
            <b>About the Instructor</b>
            <p>This comprehensive course on Computer Studies for Class 12 provides a solid foundation in the field of computer science. Explore the fundamentals of computer science, delve into programming concepts, learn about data structures and algorithms, and understand software development principles. Engage in interactive activities such as the 'Code Master Challenge' game and test your knowledge with the 'Computing Concepts Quiz.' Gain valuable insights into computer networks, security, database management systems, and emerging technologies. Equip yourself with the essential knowledge and skills needed to excel in the dynamic world of computing.</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ backgroundColor: "#0A033C", color: "#FFFF", paddingLeft: "10px", paddingTop: "5px", borderRadius: 5, width: "90%", display: "flex", flexDirection: "row", marginTop: "200px" }}>
              <div>
                <p style={{ opacity: "0.8", fontSize: "13px", marginBottom: "15px" }}>Sections</p>
                <p style={{ opacity: "0.8", fontSize: "13px", marginBottom: "15px" }}>Games</p>
                <p style={{ opacity: "0.8", fontSize: "13px", marginBottom: "15px" }}>24</p>
                <p style={{ opacity: "0.8", fontSize: "13px", marginBottom: "15px" }}>sections</p>
                <p style={{ opacity: "0.8", fontSize: "13px", marginBottom: "15px" }}>Language</p>
                <p style={{ opacity: "0.8", fontSize: "13px", marginBottom: "15px" }}>Instructor</p>
                <p style={{ opacity: "0.8", fontSize: "13px", marginBottom: "15px" }}>Price</p>
              </div>
              <div style={{ marginLeft: "100px" }}>
                <p style={{ fontSize: "14px", marginBottom: "9px" }}>4</p>
                <p style={{ fontSize: "14px", marginBottom: "9px" }}>8</p>
                <p style={{ fontSize: "14px", marginBottom: "9px" }}>Lectures 90-min</p>
                <p style={{ fontSize: "14px", marginBottom: "9px" }}>sections</p>
                <p style={{ fontSize: "14px", marginBottom: "9px" }}>English</p>
                <p style={{ fontSize: "14px", marginBottom: "9px" }}></p>
                <p style={{ fontSize: "14px", marginBottom: "9px" }}>RS 500</p>
              </div>
            </div>
            <Button onClick={Enrollcourse} style={{ backgroundColor: "#FF6652", color: "White", width: "90%", height: "6%", borderRadius: 5, marginTop: "5px" }}>Enroll Course</Button>
          </Grid>
          <SuccessModal open={isModalOpen} onClose={closeModal} />
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default App;
