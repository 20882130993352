import React from "react";
import Header from "../components/Header";
import Course from "../assests/Numbercourse.svg";
import { Grid, TextField, IconButton, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Cards from "../components/cards";
import Footer from "../components/footer";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Allboards, Coursecontent, allcoursecontent } from "../actions/overall";
import overallservices from "../services/overallservices";

const styles = {
  searchbox: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
  },
  searchInput: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B0B0B0",
    },
  },
};

export default function Courses() {
  const [filteredData, setFilteredData] = useState([]);
  const Getallcourse = useSelector((state) => state.courses);
  const [searchQuery, setSearchQuery] = useState("");
  const [showenrollcourse, setenrollcourse] = useState("");
  const [courseIds, setCourseIds] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allcoursecontent())
      .then((res) => {
        if (res.message == "error") {
        }

        setFilteredData(res.data);
        console.log("filter:    .", res.data);
      })
      .catch((err) => {
        console.log("Error while listing the data");
      });
  }, []);
  const { user } = useSelector((state) => state.auth);
  const token = localStorage.getItem("user");
  const userID = user ? user.userId : null;

  useEffect(() => {
    overallservices
      .getenrolledcourse(userID)
      .then((response) => {
        if (response) {
          const courseIds = response.data.map((item) => item.courseId);
          setCourseIds(courseIds);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);

  return (
    <>
      <Grid style={{ backgroundColor: "#fbf1ea" }}>
        <Header />
        <Grid style={{ backgroundColor: "#fbf1ea" }}>
          <img
            style={{ width: "80%", marginLeft: "100px" }}
            src={Course}
            alt="Course"
          />
        </Grid>
        <Grid className="secondgrid">
          <Box sx={styles.searchbox}>
            <TextField
              id="search"
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton type="submit" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              sx={styles.searchInput}
            />
          </Box>

          {user ? (
            <div className="card-grid">
              {filteredData
                ?.filter(
                  (e) =>
                    e.name &&
                    typeof e.name === "string" &&
                    e.name?.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((e, idx) => {
                  if (e.status === 3 && !courseIds.includes(e.id)) {
                    return (
                      <Cards
                        key={e.id}
                        uid={e.user.id}
                        id={e.id}
                        number={idx}
                        image={e.img}
                        name={e.name || "No Name"}
                        details={e.user.username}
                        description={`Grade: ${e.grade.name} ${e.board.name}`}
                        gradename={`Grade ${e.grade.name}`}
                        status={e.status}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          ) : (
            <div className="card-grid">
              {filteredData
                ?.filter(
                  (e) =>
                    e.name &&
                    typeof e.name === "string" &&
                    e.name.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((e, idx) => {
                  if (e.status === 3) {
                    return (
                      <Cards
                        key={e.id}
                        uid={e.user.id}
                        id={e.id}
                        number={idx}
                        image={e.img}
                        name={e.name || "No Name"}
                        details={e.user.username}
                        description={`Grade: ${e.grade.name} ${e.board.name}`}
                        gradename={`Grade ${e.grade.name}`}
                        status={e.status}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          )}
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
