import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetPassword = () => {
    const { resetToken } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const handleResetPassword = async () => {
        // Check if password and confirmPassword match
        if (password !== confirmPassword) {
            setSnackbar({ open: true, message: "Password and Confirm Password do not match", severity: 'error' });
            return;
        }
        try {
            const res = await axios.post('/api/auth/forgetPassword', { resetToken, password, confirmPassword })
            setSnackbar({ open: true, message: 'Password reset successfully! ', severity: 'success' });
            console.log(res)

        } catch (error) {
            setSnackbar({ open: true, message: error.response.data.message, severity: 'error' })
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 8,
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Reset Password
                </Typography>
                {/* 
          Use the 'token' variable in your component logic as needed, for example, in API calls
        */}
                <TextField
                    label="New Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                    onClick={handleResetPassword}
                >
                    Reset Password
                </Button>
            </Box>

            {/* Snackbar for displaying error and success messages */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ResetPassword;
