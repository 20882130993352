import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    Get_ALL_ORGANIZATIONS
} from './types'
import AuthService from '../services/auth.service';
import overallservices from '../services/overallservices';
export const register = (username, email, password,course_code) => (dispatch) => {
    // const roles = ["user"]
    return AuthService.register(username, email, password,course_code)
        .then(response => {
           
            dispatch({
                type: REGISTER_SUCCESS
            })

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message
            })
            return Promise.resolve()
        })
        .catch(error => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
                   
            dispatch({
                type: REGISTER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject()
        })
}

export const login = (email, password) => (dispatch) => {
    return AuthService.login(email, password).then(
        (response) => {
            dispatch({
                type: SET_MESSAGE,
                payload: 'loging Success',
            });

            console.log('RESPONSE', response)
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: response },
            });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return error.response;
        }
    );
};
export const changePassword = (email, oldPassword, newPassword) => (dispatch) => {
    return AuthService.changePassword(email, oldPassword, newPassword)
        .then((response) => {
            dispatch({
                type: SET_MESSAGE,
                payload: response.message
            })
            return Promise.resolve(response)
        })
        .catch((error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject(error)
        })
}
export const logout = () => (dispatch) => {
    AuthService.logout();
    
    dispatch({
        type: LOGOUT,
    });
};
export const organization = () => (dispatch) => {
    AuthService.organization();
    
    dispatch({
        type: Get_ALL_ORGANIZATIONS,
    });
};
