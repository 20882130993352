import jwt_decode from "jwt-decode";
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
} from "../actions/types";


const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? {
        isLoggedIn: true,
        user,
        role: jwt_decode(user.token).role,
        organization: jwt_decode(user.token).organization,
        org_id: jwt_decode(user.token).org_id,
        course_code:null
    }
    : { isLoggedIn: false, user: null, role: null, organization: null, org_id: null,course_code:null };

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                role: jwt_decode(payload.user.token).role,
                organization: jwt_decode(user.token).organization,
                org_id: jwt_decode(user.token).org_id,
                course_code:jwt_decode(user.token).course_code
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                role: null,
                organization: null,
                org_id: null,
                course_code:null
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                role: null,
                organization: null,
                org_id: null,
                course_code:null
            };
        default:
            return state;
    }
}