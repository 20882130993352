
import React from 'react';

import Header from "../components/Header";
import Footer from '../components/footer';
const PrivacyPolicy = () => {
  return (
    <>    <Header/>
    <div className="privacy-policy">
      <h1 className="privacy-policy-title">Privacy Policy</h1>

      <section className="privacy-policy-section">
        <h2>1. Information We Collect</h2>
        <p>In that, we collect user data related to assessments and activities.</p>
      </section>

      <section className="privacy-policy-section">
        <h2>2. How We Use Your Data</h2>
        <p>This data is used to strengthen the user experience and provide an adaptive learning experience.</p>
      </section>

      <section className="privacy-policy-section">
        <h2>3. Data Storage and Sharing</h2>
        <p>The data collected is stored in our database and is not shared with any other party.</p>
      </section>

      <section className="privacy-policy-section">
        <h2>4. Data Confidentiality</h2>
        <p>iParhai will always keep user data confidential and private.</p>
      </section>
    </div>
    <Footer/>
    </>

  );
};

export default PrivacyPolicy;
