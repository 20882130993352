import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from '../Pages/home';
import axios from 'axios';
import About from "../Pages/about";

import Enrollpage from '../Pages/enrollpage';
import Enroll from '../Pages/Coursedetails';
import Courses from '../Pages/courses';
import Mylearning from '../Pages/mylearning';
import PrivacyPolicy from '../Pages/privacy';
import ResetPassword from '../components/ResetPassword';
axios.defaults.baseURL = 'https://api.iparhai.com/';
// axios.defaults.baseURL = 'http://localhost:8080';

function Navigation() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/"  element={<Home />} /> 
        <Route path="/about"  element={<About />} /> 
        <Route path="/privacypolicy"  element={<PrivacyPolicy />} />
        <Route path="/mylearning"  element={<Mylearning />} /> 
       <Route path="/Courses" element={<Courses/>}/>
        <Route path="/enrollpage/:id/:uid" element={<Enrollpage />} /> 
        <Route path="/coursedetails/:id/:uid" element={<Enroll />} />
        <Route path="/resetpassword/:resetToken" element={<ResetPassword />} />
     
      </Routes>
    </HashRouter>
  );
}

export default Navigation;
