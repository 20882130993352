import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Student from '../assests/Student.svg';
import Sideblue from "../assests/Rectangle.svg"
import Bg from "../assests/Bg.svg"
import Cards from '../components/cards';
import Ai from "../assests/Ai.svg"
import Videofile from "../assests/video.mp4"
import Header from "../components/Header";
import Aboutimg from "../assests/about.svg"
import Sir from "../assests/sir.jpg"
import Buildby from "../assests/build.svg"
import pasha from "../assests/pasha.svg"
import shamsi from "../assests/shamsi.svg"
import fast from "../assests/fast.svg"
import ncai from "../assests/ncai.svg"
import Footer from '../components/footer';

const ResponsiveGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
  },
}));

export default function About() {
  return (
    <>
      <Header />
      <Box>
        <ResponsiveGrid container spacing={2} sx={{ marginTop: 5 }}>
          <Grid item xs={12} sm={6}>
            <Grid className='firstabout'>
              <a className='about' >A little <a className='little'> About Us</a> </a>
              <p className='abouttext'>iParhai is an EdTech startup that offers a wide range of professional courses for students, professionals, and anyone who is looking to upskill or reskill themselves. We provide high-quality education and training that is designed to meet the demands of the modern workforce. Our courses are designed by experts in their respective fields.</p>
              <a className='about' >Our  <a className='little'>Vision</a> </a>
              <p className='abouttext'>At iParhai, we believe in democratizing education and making it accessible to everyone, regardless of their background or location. We offer courses in various domains, including programming, web development, design, data science, and many more. Our courses are designed to be practical and hands-on, allowing our students to gain real-world skills that they can apply in their careers.</p>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
            <img src={Aboutimg} alt="About Us" style={{ maxWidth: '100%' }} />
          </Grid>
       
        </ResponsiveGrid>
        <div className='secabout'>
          <a className='offer' >What <a className='little'> We Offer</a> </a>
  <p className='text2'>
    Embark on a captivating visual journey and explore the immersive world of iParhai,
    where engaging courses come to life. Watch our introductory video to discover the
    power of interactive learning and unlock a new realm of educational possibilities.
  </p>
  <div className='video-container'>
    <video className='video' controls>
      <source src={Videofile} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</div>
<ResponsiveGrid container spacing={2} sx={{ marginTop: 5 }}>
          <Grid item xs={12} sm={6}>
            <Grid className='firstabout'>
              <a className='about' >About <a className='little'>CEO</a> </a>
              <p className='abouttext'>iParhai has been founded in the year 2022. The founder, Dr. Jawwad A Shamsi holds a PhD. in Computer Science from Wayne State University, MI, USA. He is currently serving as a Professor of Computer Science and Dean Computing at FAST-National University of Computer and Emerging Sciences. The founder has a vision to provide quality education for everyone.
iParhai has been founded in the year 2022. The founder, Dr. Jawwad A Shamsi holds a PhD. in Computer Science from Wayne State University, MI, USA. He is currently serving as a Professor of Computer Science and Dean Computing at FAST-National University of Computer and Emerging Sciences. The founder has a vision to provide quality education for everyone.</p>      </Grid>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
            <img src={Sir} alt="About Us" style={{ maxWidth: '100%' }} />
          </Grid>
       
        </ResponsiveGrid>
        <div className='secabout'>
          <a className='offer' >Collabraters <a className='little'>& Awards</a> </a>
  <p className='text2'>
  iParhai is proud to have partnered with some of the most esteemed institutions in the field of education, including Shamsi School, NCAI, Comics College, and FAST-NU. These partnerships have allowed us to offer top-quality courses and programs to our students. We're also honored to have received awards and recognition from esteemed organizations such as P@SHA, Youth Parliament, Sindh Government, and Generation School. These awards serve as a testament to the hard work and dedication that our team puts into creating exceptional learning experiences for our students.
  </p>
  <div className="image-container">
  <img src={Buildby} alt="About Us" className="responsive-image" />
  <img src={ncai} alt="About Us" className="responsive-image" />
  <img src={shamsi} alt="About Us" className="responsive-image" />
  <img src={fast} alt="About Us" className="responsive-image" />
  <img src={pasha} alt="About Us" className="responsive-image" />
</div>
</div>
<Footer/>
      </Box>
    </>
  )
}
