import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';

const register = (username, email, password,course_code,roles,organization) => {
    console.log('CREE', { username, email, password,course_code,roles,organization})
    return axios.post('api/auth/signup', {
        username,
        email,
        password,
        course_code,
        roles:["user"],
        organization:["3"]
        
    })
}

const login = (email, password) => {
    return axios
        .post('api/auth/signin', {
            email,
            password,
        })
        .then(response => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data
        })
}





const logout = () => {
    localStorage.removeItem("user")
}

const getAllUsers = () => {
    return axios
        .get(`api/auth/users`)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

const resendOTP = async (email) => {
    try {
      const res = await axios.post("/api/auth/resendOTP", { email });
      return res;
    } catch (error) {
      return error.response;
    }
  };

const changePassword = (email,oldPassword, newPassword) => {
    return axios
        .put('api/auth/changePassword', {
            email,
            oldPassword,
            newPassword
        })
        .then(response => {
            return response.data
        })
        .catch(error => {
            return error.response.data
        })
}


export default {
    register,
    login,
    resendOTP,
    logout,
    getAllUsers,
    changePassword
}