import {
    faArrowCircleRight,
    faCross,
    faMessage,
    faX,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 
  import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

  import { Box, Button, Input, Paper, Typography } from "@mui/material";
  import axios from "axios";
  import React from "react";
  import { useState } from "react";
  import { BeatLoader } from "react-spinners";
  
  function ChatModal() {
    const [chatOpen, setChatOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [responseMessage, setResponseMessage] = useState("");
    const [loading, setLoading] = useState(false);
  
    const handleMessage = (e) => {
      setMessage(e.target.value);
    };
  
    const sendMessage = async () => {
      setLoading(true);
      await axios.post(`/api/chatbot/${message}`).then((response) => {
        setResponseMessage(response.data.choices[0].message.content);
      });
      setLoading(false);
    };
  
    return (
      <Box sx={{ position: "fixed", bottom: "35px", right: "5%", zIndex: 9999 }}>
        {chatOpen && (
          <Paper
            elevation={6}
            sx={{
              position: "fixed",
              maxWidth: "50em",
              maxHeight: "30em",
              overflowY: "scroll",
              bottom: "8em",
              right: "5%",
              p: 5,
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", mb: 5 }}>
              Hey Learner!  Ask me a Question... 😀
            </Typography>
            {responseMessage !== "" && (
              <Paper sx={{ p: 2, color: "black", bgcolor: " #fbf1ea" }}>
                <Typography>{responseMessage}</Typography>
              </Paper>
            )}
            {loading && (
              <Paper sx={{ ml:30,width:'25%', p: 2, color: "white", bgcolor: " #fbf1ea" }}>
                <BeatLoader size={8}/>
              </Paper>
            )}
            <Box
              sx={{
                mt: 3,
                display: "flex",
                alignItems: "center",
                width: "100%",
                borderRadius: "20px",
                border: "1px solid #ccc",
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Input
                variant="outlined"
                onChange={handleMessage}
                sx={{
                  flex: 1,
                  padding: "10px",
                  borderRadius: "20px 0 0 20px",
                  border: "none",
                  outline: "none",
                  fontSize: "16px",
                  boxSizing: "border-box",
                }}
                placeholder="Type your message..."
              />
              <Button
                sx={{
                  borderRadius: "0 20px 20px 0",
                  color:"#0A033C"
                }}
                variant="contained"
                
                disabled={message === ""}
                onClick={sendMessage}
              >
              <ArrowForwardIcon sx={{ ml: 1 }} /> 
              </Button>
            </Box>
          </Paper>
        )}
        <Box
          sx={{
            backgroundColor:"#0A033C",
            width: "4em",
            height: "4em",
            borderRadius: 10,
            cursor: "pointer",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            setResponseMessage("");
            setChatOpen(!chatOpen);
          }}
        >
          <FontAwesomeIcon icon={!chatOpen ? faMessage : faX} />
        </Box>
      </Box>
    );
  }
  
  export default ChatModal;