import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useState,useEffect } from 'react';
import Loading from './loading';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const Score1 = [ 7, 8, 9];
const randomIndex = Math.floor(Math.random() * Score1.length);
const randomScore = Score1[randomIndex];
export default function BasicGrid({ score,outOf }) {
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state as true

  useEffect(() => {
 
    const delay = 5000; 
    setTimeout(() => {
      setIsLoading(false); 
    }, delay);
  }, []);
  return (
    
    <Box sx={{ flexGrow: 1 }}>
       {isLoading ? (
     
        <div><Loading/></div>
      ) : (
      <Grid container spacing={2}>
        <Grid item xs={12} style={{backgroundColor:"#0A033C",color:"#FFFF",borderRadius:"10px"}}>
          <div style={{display:"flex",flexDirection:"column"}}>
        <b>Total No of Question:  {outOf}</b>
         <b>Score: {score}</b>
         </div>
         <p>Congratulations! you have passed the test 😎 </p>

     
        </Grid>
      
  
        <Grid item xs={12} style={{backgroundColor:" #fbf1ea",marginTop:"10%",paddingBottom:"5%"}}>
          <b>Recomended Skills</b>
          <p>This includes all the AI generated recommendations to improve your skills</p>
          <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8} >
          <Item><b> Chemical Equation</b></Item>
        </Grid>
      
  
        <Grid item xs={8} >
          <Item><b>Branches of Chemistry </b></Item>
        </Grid>
        <Grid item xs={8} >
          <Item><b>Atomic Structure </b></Item>
        </Grid>
       
      </Grid>
    </Box>
        </Grid>
      </Grid>
 )}
    </Box>
  );
}
