import { Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Ilogo from "../assests/ifinal.svg";
import Mail from "../assests/mail.svg";
import Phone from "../assests/phone.svg";
import Facebook from "../assests/Facebook.svg";
import Insta from "../assests/Instagram.svg";
import Linked from "../assests/linked.svg";
import Twitter from "../assests/Twitter.svg";
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function Footer() {
  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = useState('#fbf1ea'); // Default color
  useEffect(() => {
    if (location.pathname === '/') {
      setBackgroundColor('#fbf1ea');
    } else if (location.pathname === '/courses' || location.pathname === '/about') {
      setBackgroundColor('#FFFF');
    }
  }, [location]);

  const footerStyle = {
    backgroundColor,
  };

  const logoStyle = {
    width: '100%',
    maxWidth: '200px',
  };

  const linkStyle = {
    textDecoration: 'none', 
    color: 'black', 
  };

  return (
    <Grid container spacing={3} sx={footerStyle} className='footerr'>
      <Grid item xs={12} sm={4}>
        <div>
          <img src={Ilogo} alt="Logo" style={logoStyle} />
          <p className='textfooter'>
            © [2023] iParhai. All rights reserved. Empowering learners with engaging education for a brighter future.
          </p>
          <p className='samemail'>
            <img src={Mail} alt="Mail Icon" />
            info@iParhai.com
          </p>
          <p className='samemail'>
            <img src={Phone} alt="Phone Icon" />
            (+92) 3343661756
          </p>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <p><Link to="/" style={linkStyle}>Home</Link></p>
        <p><Link to="/courses" style={linkStyle}>Course</Link></p>
        <p><Link to="/about" style={linkStyle}>About us</Link></p>
        <p><Link to="/privacypolicy" style={linkStyle}>Privacy Policy</Link></p>
      </Grid>
      <Grid item xs={12} sm={4}>
        <b>Follow us</b>
        <div style={{ display: 'flex', columnGap: '20px' }}>
          <Link to="https://www.linkedin.com/company/80367400/admin/">
            <img src={Linked} alt="LinkedIn Icon" />
          </Link>
          <Link to="https://www.facebook.com/IParhai-105665001908785">
            <img src={Facebook} alt="Facebook Icon" />
          </Link>
          <Link to="https://www.instagram.com/iparhai/">
            <img src={Insta} alt="Instagram Icon" />
          </Link>
          <Link to="https://twitter.com/iparhai">
            <img src={Twitter} alt="Twitter Icon" />
          </Link>
        </div>
      </Grid>
    </Grid>
  );
}
