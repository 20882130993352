import React from 'react';
import Loadingsvg from "../assests/iparhaiLoader.svg";

export default function Loading() {
  return (
    <div className="loading-container">
      <img src={Loadingsvg} alt="Loading" className="loading-image" />
    </div>
  );
}
