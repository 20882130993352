import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Student from "../assests/Student.svg";
import Sideblue from "../assests/Rectangle.svg";
import Bg from "../assests/Bg.svg";
import Cards from "../components/cards";
import Ai from "../assests/Ai.svg";
import Videofile from "../assests/video.mp4";
import Frame1 from "../assests/Frame1.svg";
import Frame2 from "../assests/Frame1.svg";
import Ilogo from "../assests/ifinal.svg";
import Frame3 from "../assests/Frame3.svg";
import { Allboards, Coursecontent, allcoursecontent } from "../actions/overall";
import Frame4 from "../assests/Frame4.svg";
import Mail from "../assests/mail.svg";
import Phone from "../assests/phone.svg";
import { TextField, Typography } from "@mui/material";
import Facebook from "../assests/Facebook.svg";
import Insta from "../assests/Instagram.svg";
import Linked from "../assests/linked.svg";
import Twitter from "../assests/Twitter.svg";
import Footer from "../components/footer";
import { useSelector, useDispatch } from "react-redux";
import ChatModal from "../components/ChatModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloud,
  faLightbulb,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CustomImage = styled("img")({
  width: "100%",
  maxWidth: "400px",
  height: "auto",
  flexShrink: 0,
  borderRadius: "255px 255px 0px 255px",
  background:
    "linear-gradient(180deg, rgba(255, 102, 82, 0.00) 0%, #FF6652 100%, #FF6652 100%)",
  position: "relative",
  zIndex: 1,
});

const CustomRectangle = styled("img")({
  width: "100%",
  maxWidth: "455px",
  height: "auto", // Automatically adjust height
  flexShrink: 0,
  position: "absolute",
  top: "57%",
  left: "50%", // Center horizontally
  transform: "translate(-50%, -50%)", // Center vertically
});

const BackgroundImage = styled("img")({
  position: "relative",
  width: "90%",
  top: -93,
  // Automatically adjust height
});

const OverlayContainer = styled(Box)({
  position: "relative",
});

const OverlayImage = styled("img")({
  position: "absolute",
  top: -23,
  left: -0,
  width: "auto",
  maxHeight: "100%",
});

const Secondbox = styled("div")({});

export default function Home() {
  const dispatch = useDispatch();
  const [isMobileView, setIsMobileView] = useState(false);
  const [courselist, setcourselist] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const Getallcourse = useSelector((state) => state.courses);

  useEffect(() => {
    dispatch(allcoursecontent())
      .then((res) => {
        if (res.message == "error") {
        }

        setFilteredData(res.data);
      })
      .catch((err) => {
        console.log("Error while listing the data");
      });
  }, []);
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth <= 600);
    };

    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <>
      <Header />
      <ChatModal />
      <Box>
        <Grid container spacing={2} sx={{ marginTop: 5 }}>
          <Grid item xs={12} sm={6}>
            <Grid className="firstgrid">
              <a className="nevertext">#Never Stop Learning</a>
              <p className="text-4xl font-semibold">
                Engaging and Accessible online courses for all
              </p>
              <p className="my-2">
                Discover the power of engaging and accessible online courses for
                all. Dive into a world of interactive learning experiences,
                where education comes alive through games, quizzes, and more.
              </p>
              <Grid className="mainnumber">
                <Grid className="numbers" sx={{ display: "flex", gap: 3 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography fontSize={18} fontWeight={800}>
                      {" "}
                      Chatbot
                    </Typography>
                    <FontAwesomeIcon icon={faMessage} color="#FF6652" />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography fontSize={18} fontWeight={800}>
                      {" "}
                      Recomendations
                    </Typography>
                    <FontAwesomeIcon icon={faCloud} color="#FF6652" />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography fontSize={18} fontWeight={800}>
                      {" "}
                      Virtual Learning Environment{" "}
                    </Typography>
                    <FontAwesomeIcon fa={faLightbulb} color="#FF6652" />
                  </Box>
                  {/* <a className="number">Chatbot</a>{" "}
                  <a className="number">Recomendations</a>{" "}
                  <a className="number">Virtual Learning Environment</a> */}
                </Grid>
                {/* <Grid className='grade'>
                  <a>Students</a> <a >Courses</a> <a >Updated Content</a>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} sx={{}}>
            <OverlayContainer>
              {isMobileView ? (
                <CustomImage src={Student} alt="Student" />
              ) : (
                <>
                  <BackgroundImage src={Bg} alt="Background" />
                  <OverlayImage src={Student} alt="Student" />
                </>
              )}
            </OverlayContainer>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} className="thrdgrid">
        <Grid item xs={12} sm={6} style={{ paddingLeft: "120px" }}>
          <div>
            <p className="strength">#strengthenyourweaknesses</p>
            <b>AI-Powered Personalized Recommendations</b>
            <p className="text2">
              Unleash the power of artificial intelligence as iParhai analyses
              your assessments and provides tailored recommendations to address
              your individual weaknesses. Accelerate your learning journey with
              personalized content that targets your areas of improvement,
              empowering you to reach your full potential.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={Ai} alt="AI" />
        </Grid>
      </Grid>

      <Grid className="secondgrid">
        <p className="courses">#courses</p>
        <p className="qualified">Qualified courses for students</p>
        <p className="text2">
          Discover a range of qualified courses designed specifically for
          students. Expand your knowledge, sharpen your skills, and achieve
          academic excellence with our comprehensive selection of educational
          offerings.
        </p>

        <div className="card-container">
          {filteredData?.map((e, idx) => {
            if (idx >= 0 && idx <= 9 && e.status === 3) {
              return (
                <Cards
                  key={e.id}
                  uid={e.user.id}
                  id={e.id}
                  number={idx}
                  image={e.img}
                  name={e.name}
                  details={e.user.username}
                  description={`Grade: ${e.grade.name} ${e.board.name}`}
                  gradename={`Grade: ${e.grade.name}`}
                />
              );
            }
          })}
        </div>
      </Grid>

      <div className="fourthgrid">
        <p className="strength">#discover</p>
        <b>A Visual Introduction to Engaging Courses.</b>
        <p className="text2">
          Embark on a captivating visual journey and explore the immersive world
          of TechParhai, where engaging courses come to life. Watch our
          introductory video to discover the power of interactive learning and
          unlock a new realm of educational possibilities.
        </p>
        <div className="video-container">
          <video className="video" controls>
            <source src={Videofile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <Grid container spacing={2} className="fifthgrade">
        <Grid item xs={12} sm={6}>
          <div>
            <p className="strength">#whatsnew</p>
            <b>Master the skills to drive your career</b>
            <p className="text2">
              Unleash your potential and drive your career forward with our
              cutting-edge skill mastery program. Gain expertise in modern
              technologies and equip yourself with the knowledge to excel, all
              while enjoying an engaging and interactive learning experience.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={6} md={6} className="griditem">
              <p className="sameitem">
                {" "}
                <img src={Frame1} alt="Frame1" /> Stay motivated with engaging
                instructions
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="griditem">
              <p className="sameitem">
                {" "}
                <img src={Frame2} alt="Frame2" /> Build skills and knowledge in
                your way
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="griditem">
              <p className="sameitem">
                {" "}
                <img src={Frame3} alt="Frame3" /> Keep up with the latest in the
                cloud
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="griditem">
              <p className="sameitem">
                {" "}
                <img src={Frame4} alt="Frame4" /> Free courses with games,
                quizzes and more.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
}
