import axios from "axios";
import { useSelector } from "react-redux";


const allcourses = () => {
  return axios
    .get("/api/courses/allcourses")
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.resolve(error);
    });
};
const roles = () => {
  return axios
    .get("/api/auth/roles")
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.resolve(error);
    });
};
const Scoresubmit = (contentId, userId, score) => {
 
  return axios
    .post('api/updatescore', {
    contentId : "b240d430-18be-11ee-ad77-b3828f964df9",
    userId: 58,
    score : 0.6
})
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
const predict = async () => {
  try {
    const response = await axios.get("/api/assessments/predict", {
      params: [
        {
          skill_id: "0bcb5fa0-18bc-11ee-ad77-b3828f964df9",
          user_id: "58",
          correct: 0.6,
        }
      ]
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

const allboards = () => {
  return axios
    .get("/api/board/getAllBoards")
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.resolve(error);
    });
};
const coursecontent = (userId, courseId) => {
  return axios
    .get(`api/content/getContent/${courseId}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
const videocontent = async (video, token) => {
  try {
    const response = await axios.get(`/api/content/${video}`, {
      headers: {
        "x-access-token": token
      }
    });
    console.log(token, "yes token");
    return response.data;
  } catch (error) {
    console.error("Error fetching video content:", error);
    throw error;
  }
};
const enrollstudent = async (userId, courseId, token) => {
  try {
    const data = {
      userId: userId, 
      courseId: courseId, 
    };
    const response = await axios.post(
      `/api/enrollments/enroll`,data,
      {
        headers: {
          "x-access-token": token
        }
      }
    );
    if (response) {
      console.log(response, "enrollsucc");
      return response.data;
    }
  } catch (error) {
    console.error("Error enrolling:", error);
    throw error;
  }
};
const getenrolledcourse = (userId,) => {
  return axios
    .get(`/api/enrollments/getStudentEnrollment/${userId}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};



export default {
  roles,
  allcourses,
  allboards,
  coursecontent,
  videocontent,
  Scoresubmit,
  predict,
  enrollstudent,
  getenrolledcourse
};
