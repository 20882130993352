import overallservices from "../services/overallservices";

import {
    SET_MESSAGE,LOGIN_SUCCESS, SAVE_RESPONSE
} from './types'

export const Allboards = () => (dispatch) => {
    return overallservices.allboards()
        .then((response) => {
            dispatch({
                type: SET_MESSAGE,
               
            })
            return Promise.resolve(response)
        })
        .catch((error) => {
            // const message =
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //     error.message ||
            //     error.toString();

            // dispatch({
            //     type: SET_MESSAGE,
            //     payload: message,
            // });
            return Promise.reject(error)
        })
}

export const allcoursecontent=()=>(dispatch)=>{
 return overallservices.allcourses()
 
 .then((response) => {
    console.log(response,"here response")
    dispatch({
      type: SAVE_RESPONSE,
      payload:{showcourse:response.data} ,
       
    })
    return Promise.resolve(response)
})
.catch((error) => {
    // const message =
    //     (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //     error.message ||
    //     error.toString();

    // dispatch({
    //     type: SET_MESSAGE,
    //     payload: message,
    // });
    return Promise.reject(error)
})
}