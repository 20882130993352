import React from 'react';
import { Card, Button } from 'react-bootstrap';
import Course from "../assests/course.svg"
import Game from "../assests/game.svg"
import Section from "../assests/section.svg"
import { useNavigate } from "react-router-dom";

const SimpleCard = (props) => {
  const Navigate = useNavigate();

  const Onclickbutton = () => {
    const token = localStorage.getItem("user");
    if (token) {
      Navigate(`/coursedetails/${props.id}/${props.uid}`);
    } else {
      alert("Please login first");
    }
  }
  const Onclickenroll = () => {
    const token = localStorage.getItem("user");
    if (token) {
      Navigate(`/enrollpage/${props.id}/${props.uid}`);
    } else {
      alert("Please login first");
    }
  }
  const { number, name, description, details, id, uid,gradename } = props;
  const NavigateToEnroll = () => {
    const courseDetailPath = `/coursedetails/${id}/${uid}`;
   
    Navigate(courseDetailPath);
  };
  return (
    <Card style={{ width: '384px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: "#FFFF", padding: 10 }}>
      <div className='cardopper'>
        <div className='gradenumber'>{description}</div>
        <div className='course-details'>
          <div className='coursename'>{name}</div>
          <div className='teachername'>{details}</div>
        </div>
      </div>
      <Card.Body>
        <div className='sectionno'>
          <img style={{ marginLeft: "10px" }} src={Course} /> <p className='section'>12 sections</p> <img src={Game} style={{ marginLeft: "10px" }} /> <p className='section' >10 Quizes </p> <img src={Section} style={{ marginLeft: "10px" }} /> <p className='section'>1 Game</p>
        </div>
        <Card.Text>
          <b>Description: </b>
          {gradename} {name} is offered by a very skillfull teacher {details} 
        </Card.Text>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button className='coursedetailbtn' onClick={NavigateToEnroll} >Start Learning</Button>
         
        </div>
      </Card.Body>
    </Card>
  );
};

export default SimpleCard;
