import jwt_decode from "jwt-decode";
import {
    SAVE_RESPONSE
} from "../actions/types";
const initialState = {
    Getallcourse:null,
  };


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    
        case SAVE_RESPONSE:
            return {
                ...state,
                Getallcourse: payload.showcourse,
            };
        default:
            return state;
    }
}