import React, { useState } from "react";
import overallservices from "../services/overallservices";
import { useEffect } from "react";
import Iframe from 'react-iframe'
const Video = ({Dynamicvideo}) => {
  const [isLoading, setLoading] = useState(false)
  console.log(Dynamicvideo,"linkhere")
  return (
    <>
    {
      isLoading &&   <div style={{display:"flex",justifyContent:"center"}}>
     
    </div>
    }
    {
      !isLoading && <Iframe
      url={Dynamicvideo}
      width="640px"
      height="380px"
      allow="autoplay"
    />
    }
    </>
  );
};

export default Video;
