import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import EnrollCards from '../components/enrollcard';
import overallservices from '../services/overallservices';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/footer';

export default function Mylearning() {
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const userID = user.userId;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    overallservices
      .getenrolledcourse(userID)
      .then((response) => {
        setFilteredData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Error:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container">
      <Header />
      <div className="card-container12" style={{ minHeight: '400px' }}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          filteredData && filteredData.length > 0 ? (
            filteredData.map((e) => (
              <EnrollCards
                uid={e.userId}
                id={e.courseId}
                name={e.course.name}
                description={`Grade: ${e.course.grade.name} ${e.course.board.name}`}
              />
            ))
          ) : (
            <div style={{ marginLeft:"200px"}}>
            <p>Hey user you havent enrolled any course ! Please enroll a course to start learning! 😊</p>
          </div>
          
          )
        )}
      </div>
      <Footer />
    </div>
  );
}
