// import React, { useEffect, useState } from "react";
// import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from "./store";
import Navigation from "./navigation";

// function App() {
//   return (
//     <div className="App">
//         <Provider store={store}>
//      <Navigation/>
//            </Provider>
//     </div>
//   );
// }

// export default App;




import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Import your components here
// import Home from './Home';

function App() {
  // Define the Poppins font in your theme
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FF6652",
      },
      secondary: {
        main: "#FFFFFF",
      },
      light: {
        main: "#FFFFFF",
      },
    },
    typography: {
      fontFamily: "Poppins, sans-serif",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
     <Navigation/>
           </Provider>
    </ThemeProvider>
  );
}

export default App;


