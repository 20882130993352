import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import {
  Container,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  LinearProgress,
  Button,
} from '@mui/material';
import { Rectangle } from '../assests/Rectangle.png';
import Webcam from 'react-webcam';
import VideoIcon from '@mui/icons-material/VideoLibrary';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Header from '../components/Header';
import Footer from '../components/footer';
import AssessmentComponent from '../Pages/Assessment';
import overallservices from '../services/overallservices';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Video from '../components/video';
import { Assessment } from '@mui/icons-material';
import Dynamicvideo from '../components/Dynamicvideo';
import WebcamCapture from '../components/Test/WebcamCapture';
import { Modal, Backdrop, Fade } from '@mui/material';

const App = () => {
  const [openTopicIndex, setOpenTopicIndex] = useState(null);
  const [openSubtopicIndices, setOpenSubtopicIndices] = useState([]);
  const [selectedSubtopicIndex, setSelectedSubtopicIndex] = useState(null);
  const [open, setOpen] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const [video, setVideo] = useState('');
  const [dynamicvideo, setdynamicVideo] = useState('');
  const { id } = useParams();
  const userid = user.userId;
  const [completedSubtopicIndices, setCompletedSubtopicIndices] = useState([]);
  const [showIntroModal, setShowIntroModal] = useState(true);
  const [assessments, setAssessments] = useState([]);
  const [contentid, setcontentid] = useState([]);
  const [showAssessment, setShowAssessment] = useState(false);
  const [courseContent, setCoursecontent] = useState(null);
  const [showAssessmentScreen, setShowAssessmentScreen] = useState(false);
  const [contentType, setContentType] = useState();

  const handleAssessmentButtonClick = () => {
    setShowAssessmentScreen((prevValue) => !prevValue);
  };
  const videoOptions = {
    playerVars: {
    },
  };
  const handleIntroModalClose = () => {
    setShowIntroModal(false);
  };
  const handleTopicClick = (index) => {
    setOpenTopicIndex(index);
  };
  useEffect(() => {
    overallservices
      .coursecontent(user?.userId, id)
      .then((response) => {
        if (response.message == 'Success') {
          console.log(response)
          setCoursecontent(response.data);
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }, []);
  useState(() => {
    console.log('DATA', assessments, video);
  }, [assessments, video]);

  const handleContentView = (content) => {
    setVideo(content.video);
    console.log(content.assessments)
    setAssessments(content.assessments);
    setcontentid(content.id);
    setContentType(content.contentType);
    setdynamicVideo(content.video);
    setCompletedSubtopicIndices((prevIndices) => [...prevIndices, content.id]);
  };
  const handleSubtopicClick = (topicIndex, subtopicIndex) => {
    if (selectedSubtopicIndex === subtopicIndex) {
      setSelectedSubtopicIndex(null);
    } else {
      setSelectedSubtopicIndex(subtopicIndex);
    }
    const updatedSubtopicIndices = [...openSubtopicIndices];
    updatedSubtopicIndices[topicIndex] = subtopicIndex;
    setOpenSubtopicIndices(updatedSubtopicIndices);
  };
  const totalContents =
    courseContent?.chapters.reduce((total, chapter) => total + chapter.contents.length, 0) || 1;
  const completedContents = completedSubtopicIndices.length;
  const completionPercentage = (completedContents / totalContents) * 100;

  return (
    <>
      <Grid style={{ backgroundColor: '#fbf1ea', paddingBottom: '30px' }}>
        <Modal
          open={showIntroModal}
          onClose={handleIntroModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showIntroModal}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '8px',
                maxWidth: '400px',
                textAlign: 'center',
              }}
            >
              <h2>Welcome!</h2>
              <p>Please select content to get started.</p>
              <Button onClick={handleIntroModalClose} variant="contained" color="primary">
                Close
              </Button>
            </div>
          </Fade>
        </Modal>

        <Header />
        <Container
          style={{
            backgroundColor: '#FFFF',
            borderRadius: 20,
            padding: '20px',
            maxWidth: '80%',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <div
                style={{
                  marginTop: '20px',
                  backgroundColor: '#fbf1ea',
                  borderRadius: 5,
                  marginBottom: '10px',
                  paddingBottom: '10px',
                  paddingTop: '15px',
                  height: '80px', // Adjust the height as needed
                  overflowY: 'auto',
                }}
              >
                <b>Course Progress: {completionPercentage.toFixed(0)}%</b>
                <LinearProgress
                  variant="determinate"
                  value={completionPercentage}
                  sx={{
                    backgroundColor: '#0A033C',
                    height: 10,
                    borderRadius: 8,
                    marginTop: '10px',
                  }}
                />
              </div>
              <Typography variant="h6" gutterBottom></Typography>
              <Paper
                elevation={3}
                style={{
                  padding: '16px',
                  backgroundColor: '#fbf1ea',
                  color: 'black',
                  height: 'calc(60vh - 40px)', // Adjust the height as needed
                  overflowY: 'auto',
                }}
              >
                <List component="nav">
                  {courseContent?.chapters.map((el, index) => (
                    <div key={index}>
                      <ListItemButton onClick={() => handleTopicClick(index)}>
                        <ListItemText primary={el.name} />
                      </ListItemButton>
                      <Collapse
                        in={openTopicIndex === index}
                        style={{ height: openTopicIndex === index ? 'auto' : 0, overflow: 'hidden', transition: 'height 0.3s' }}
                      >
                        <List disablePadding>
                          {el.contents?.map((content, contentIndex) => {
                            // Log the content object to the console
                            return (
                              <ListItemButton
                                key={contentIndex}
                                sx={{ paddingLeft: 4, cursor: 'pointer' }}
                                onClick={() => {
                                  console.log(content)
                                  handleContentView(content)
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    checked={contentid === content.id}
                                    onChange={() => handleContentView(content)}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={content.name} />
                              </ListItemButton>
                            );
                          })}

                        </List>
                      </Collapse>
                      <Divider />
                    </div>
                  ))}
                </List>
              </Paper>
              <Divider />
            </Grid>
            <Grid className='w-10' item xs={4} md={0} sm={8}>
              <div style={{ position: 'relative' }}>
                {showAssessmentScreen ? (
                  <AssessmentComponent
                    assessments={assessments}
                    contentId={contentid}
                    onAssessmentSubmit={(score) => {
                      // Handle the submitted assessment score here
                      console.log('Assessment Score:', score);
                    }}
                  />
                ) : (
                  contentType === 2 ? (
                    <>
                      <Dynamicvideo Dynamicvideo={dynamicvideo} />
                      <p>
                        <b>About this Course</b>
                      </p>
                      <p>This comprehensive course on Maths for Class provides a solid foundation...</p>
                    </>
                  ) : (
                    <>
                      <Video video={video} />
                      <p>
                        <b>About this Course</b>
                      </p>
                      <p>This comprehensive course on Maths for Class provides a solid foundation...</p>
                    </>
                  )
                )}
              </div>
              <Button
                onClick={handleAssessmentButtonClick}
                variant="contained"
                color="primary"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginLeft: 200,
                  marginTop: 10,
                  backgroundColor: '#0A033C',
                  color: '#FFFF',
                }}
              >
                {showAssessmentScreen ? (
                  <>
                    Lecture
                    <AssessmentIcon sx={{ marginLeft: 2 }} />
                  </>
                ) : (
                  <>

                    Skills Test
                    <VideoIcon sx={{ marginLeft: 2 }} />
                  </>
                )}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Footer />
    </>
  );
};

export default App;
