import React from 'react';
import { Container, Grid, Paper, TextField, Button, Alert, Checkbox } from '@mui/material';
import { useState,useEffect } from 'react';
import Ilogo from "../assests/ifinal.svg";
import { CheckBox } from '@mui/icons-material';

import { useDispatch, useSelector } from "react-redux";
import { register } from '../actions/auth';



const Signup = () => {
  const textFieldStyle = {
    fontFamily: "'Poppins', Arial, sans-serif",
    backgroundColor: '#fbf1ea',
    outlineColor: '#fbf1ea',
  };
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const dispatch = useDispatch();
  const [emptyFieldsError, setEmptyFieldsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOrganizationAssociated, setIsOrganizationAssociated] = useState(false);
  const [course_code, setcourse_code] = useState("");
  const { message } = useSelector((state) => state.message);
  const registerhandle = (e) => {
    e.preventDefault();

    // setLoading(true);
    if (password !== confirmpassword) {
      setPasswordMatchError(true);
      return;
    }
  
    setPasswordMatchError(false);
    if (!username || !email || !password || !confirmpassword) {
      setEmptyFieldsError(true);
      return;
    }

    setEmptyFieldsError(false);
    dispatch(register(username, email, password,course_code))
      .then((response) => {
        setLoading(false);
        console.log("RES", response)
        alert("User Created Successfully \n OTP sent to your Email.")
        window.location.reload();
      })
     
      .catch((err) => {
        setLoading(false);
        alert(message)
        console.log(message,"yes")
       
      });
    
  };

  const buttonlogin = {
    width: "80%",
    marginTop: "2%",
    marginRight: "15%",
    borderRadius: "8px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
  };

  const buttonlogin1 = {
    width: "80%",
    marginTop: "2%",
    marginRight: "15%",
    borderRadius: "8px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: '#fbf1ea' }}>
      <Grid container spacing={0} sx={{ minHeight: '500px' }}>
        {/* Login Form */}
        <Grid item xs={12} sm={10} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Grid className='firstsignup'>
            <b>Welcome Learner! Join iParhai </b>
            <p></p>
            <b>Name</b>
            <TextField
              label="Enter Your Name"
              variant="outlined"
              margin="normal"
              style={buttonlogin}
              InputLabelProps={{ style: textFieldStyle }}
              onChange={(e) => setusername(e.target.value)}
            />
               {emptyFieldsError && !username && <div style={{ color: 'red' }}>This field is required.</div>}
            <b>Email</b>
            <TextField
              label="Enter Your Email"
              variant="outlined"
              margin="normal"
              style={buttonlogin}
              InputLabelProps={{ style: textFieldStyle }}
              onChange={(e) => setemail(e.target.value)}
            />
               {emptyFieldsError && !email && <div style={{ color: 'red' }}>This field is required.</div>}
            <b>Password</b>
            <TextField
              label="Enter Your Password"
              variant="outlined"
              type="password"
              margin="normal"
              style={buttonlogin}
              className='buttonlogin'
              InputLabelProps={{ style: textFieldStyle }}
              sx={{}}
              
              onChange={(e) => setpassword(e.target.value)}
            />
               {emptyFieldsError && !password && <div style={{ color: 'red' }}>This field is required.</div>}
             <b>Confirm Password</b>
            <TextField
              label="Enter Your Password"
              variant="outlined"
              type="password"
              margin="normal"
              style={buttonlogin}
              className='buttonlogin'
              InputLabelProps={{ style: textFieldStyle }}
              sx={{}}
              onChange={(e) => setconfirmpassword(e.target.value)}
            />
               {emptyFieldsError && !confirmpassword && <div style={{ color: 'red' }}>This field is required.</div>}
               {passwordMatchError && <div style={{ color: 'red' }}>Passwords do not match.</div>}
               <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Checkbox sx={{ marginLeft:-1.2  }} onClick={() => setIsOrganizationAssociated(!isOrganizationAssociated)} />
              <p>Are you associated with any School/Organization?</p>
            </Grid>
         
            {isOrganizationAssociated && (
              <div>
              
                <TextField
                  label="Enter Class Code "
                  variant="outlined"
                  margin="normal"
                  style={buttonlogin}
                  InputLabelProps={{ style: textFieldStyle }}
                  onChange={(e) => setcourse_code(e.target.value)}
                />
              </div>
            )}
            <Grid style={{display:"flex",flexDirection:"row"}}><CheckBox sx={{marginTop:"15px",marginRight:"10px"}}/> <p>I Agree to the Terms and Conditions* </p></Grid>
           
            <Button  onClick={registerhandle} variant="contained" color="primary" style={buttonlogin1} fullWidth sx={{ backgroundColor: "#0A033C", '&:hover': {
                  backgroundColor: "#0A033C",
                }, }}>
              Signup
            </Button>
           
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2} className='loginsecgrid'>
          <Paper sx={{ backgroundColor: '#FF6652', minHeight: '90%', padding: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={Ilogo} style={{ width: "150%" }} alt="Ilogo" />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Signup;